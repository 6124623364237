import React from 'react';
import location_pin from '../assets/images/homepage/location-pin.svg';


const SearchRestaurant = () => {
  return (
    <div className='lg:py-5 lg:flex gap-5 max-sm:mt-32 '>
      <div className="relative w-full">
        <span className='absolute left-4 lg:left-8 top-1/2 transform -translate-y-1/2  '>
          <img src={location_pin} alt="" />
        </span>
        <input type='text' 
          className='py-4 lg:py-8 lg:px-20 px-12 placeholder:text-white text-white text-2xl max-sm:mt-3 focus:outline-[#44843F] bg-[#9B9B9B] w-full rounded-full' 
          placeholder='Search Restaurant Near You'
        />
      </div>
      <button className='py-3 px-5 rounded-full bg-[#44843F] max-sm:w-full max-sm:mt-5 font-bold text-white text-2xl' >Search</button>

    </div>
  )
}

export default SearchRestaurant;
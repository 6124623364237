import '../assets/css/form.css';

import { LoadingOutlined } from "@ant-design/icons";
import { Input, notification, Spin } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { signIn } from '../common/axios_services';
import Jwt_encrypt from '../common/Jwt_encrypt';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const Signin = () => {
    let params = new URLSearchParams(window?.location?.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false)
    const [redirectUrl,] = useState(params.get('konnectrd') ? params.get('konnectrd') : '');
    const [redir,] = useState(params.get('redir') ? params.get('redir') : '');
    const [referralCode,] = useState(params.get('konnectrfc') ? params.get('konnectrfc') : '');

    const { handleSubmit, control } = useForm({
        defaultValue: {
            emailAddress: "",
            password: "",
        },
    });

    const { handleSubmit: handleMobileSubmit, control: controlMobileSubmit } = useForm({
        defaultValue: {
            emailAddress: "",
            password: "",
        },
    });

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title ? title : '',
            description: message ? message : "",
            placement: "bottom-right"
        });
    };

    const submitMe = async e => {
        setErrorMessage('');
        setFetchingData(true);
        let { emailAddress, password } = e;
        try {
            const response = await signIn({ email: emailAddress, password, app: "web", device_id: "", device_type: "", role_id: 1 })
            localStorage.setItem('konnect_token', Jwt_encrypt({ token: response?.data?.data?.token }))
            openNotificationWithIcon('success', response?.data?.title, response?.data?.message)
            if (redir) {
                navigate(`/${redir}`)
            } else {
                navigate(`/home`)
            }
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                if (err?.response?.data?.message?.toLowerCase().includes('not been verified')) {
                    navigate('/verify-email')
                }
                openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", "An error occurred while signing user in. Please reload page to try again")
            }

        }
    }

    return (
        <div className="form-pages">
            <div className="mobile">
                <Navbar />
                <div className="container form_design_pages">
                    <div className="">
                        <div className="form auth">
                            <div className="form-text">
                                <h3>Sign in to your konnect account!</h3>
                                <p>Food vendors buy bulk foodstuffs on konnect, and we deliver to them for free. They tell other food vendors & they earn monthly.</p>
                                {errorMessage ?
                                    <p className="form-error">{errorMessage}</p> : ''
                                }
                                {successMessage ?
                                    <p className="success-message">{successMessage}</p> : ''
                                }
                            </div>
                            <form onSubmit={handleMobileSubmit(submitMe)}>
                                <div className="form-group">
                                    <label htmlFor="emailAddress" style={{ width: '100%' }}>Email address</label>
                                    <Controller control={controlMobileSubmit} defaultValue="" name="emailAddress"
                                        render={({ field }) => (
                                            <Input {...field}
                                                type="email" style={{ height: '5rem' }} />
                                        )
                                        } />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <Controller name="password" defaultValue="" control={controlMobileSubmit}
                                        render={
                                            ({ field }) => (
                                                <Input.Password {...field}
                                                    type="password" style={{ height: '5rem' }} />
                                            )
                                        } />
                                </div>
                                <div>
                                    <Link style={{ display: 'block', marginBottom: 5 }} to={`/signup/?konnectrfc=${referralCode}&konnectrd=${redirectUrl}`}> New customer? <span>Create your account</span></Link>
                                    {/* <Link style={{ display: 'block', marginBottom: 5 }} to={`/verify-email`}>Confirm Email? <span>Use Passcode</span></Link> */}
                                    <Link style={{ display: 'block' }} to={`/resetsendmail`}>Lost Password? <span>Recover password</span></Link>
                                </div>
                                {
                                    !fetchingData ?
                                        <button className="bg_green" style={{
                                            height: '4.5rem', width: '100%',
                                            borderRadius: '3px', marginTop: '10%'
                                        }}>
                                            Sign in</button>
                                        :
                                        <button className="bg_green" disabled style={{
                                            height: '4.5rem', width: '100%',
                                            borderRadius: '3px', marginTop: '10%'
                                        }}>
                                            <Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                }
                                <div>
                                </div>
                            </form>
                            <div style={{ marginBottom: '20px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="desktop show-all">
                <Navbar />
                <div className="bg_grey">
                    <div className="container form_design_pages">
                        <div className="div_bg_white">
                            <div className="form aut">
                                <div className="form-text">
                                    <h3 className="noSpace">Sign in to your konnect account!</h3>
                                    <p>Food vendors buy bulk foodstuffs on konnect, and we deliver to them for free. They tell other food vendors & they earn monthly.</p>
                                    {errorMessage ?
                                        <p className="form-error">{errorMessage}</p> : ''
                                    }
                                    {successMessage ?
                                        <p className="success-message">{successMessage}</p> : ''
                                    }
                                </div>
                                <form onSubmit={handleSubmit(submitMe)}>
                                    <div className="form-group">
                                        <label htmlFor="emailAddress" style={{ width: '100%' }}>Email address</label>
                                        <Controller control={control} defaultValue="" name="emailAddress"
                                            render={({ field }) => (
                                                <Input {...field} id="emailAddress"
                                                    type="email" style={{ height: '4.5rem' }} />
                                            )
                                            } />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <Controller name="password" defaultValue="" control={control}
                                            render={
                                                ({ field }) => (
                                                    <Input.Password {...field} id="password"
                                                        type="password" style={{ height: '4.5rem' }} />
                                                )
                                            } />
                                    </div>
                                    <div style={{ marginTop: '2rem' }}></div>
                                    <div>
                                        {
                                            !fetchingData ?
                                                <button className="bg_green" style={{
                                                    height: '4.5rem', width: '100%',
                                                    borderRadius: '3px'
                                                }}>
                                                    Sign in</button>
                                                :
                                                <button className="bg_green" disabled style={{
                                                    height: '4.5rem', width: '100%',
                                                    borderRadius: '3px'
                                                }}>
                                                    <Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                        }
                                    </div>
                                </form>
                                <div style={{ marginBottom: '20px' }}></div>
                                <div className="">
                                    <Link style={{ fontSize: '1.3rem', display: 'block', marginBottom: 5 }} to={`/signup/?konnectrfc=${referralCode}&konnectrd=${redirectUrl}`}>New customer? <span>Create your account</span></Link>
                                    {/* <Link style={{ fontSize: '1.3rem', display: 'block', marginBottom: 5 }} to={`/verify-email`}>Confirm Email? <span>Use Passcode</span></Link> */}
                                    <Link style={{ fontSize: '1.3rem', display: 'block' }} to={`/resetsendmail`}>Lost Password? <span>Recover password</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Signin
import {useEffect, useState} from 'react';
import Navbar from '../../components/Navbar';
import {_fetch_user_wallet_data,  _get_profile} from '../../common/axios_services';
import NumberFormat from 'react-number-format';
import walletIcon2 from '../../assets/images/icons/wallet_icon.png';
import eyeIcon from '../../assets/images/icons/visibility_icon.png';
import right_circle_arrow from '../../assets/images/homepage/right_circle_arrow.svg';
import {Col, notification, Row} from 'antd';
import side_banner5 from '../../assets/images/homepage/side_banner_4.png';
import {useNavigate} from 'react-router-dom';
import { DoubleRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer'
import HomeSlider from '../../components/home/slider';
// import walletIcon from '../../assets/images/icons/wallet_icon.svg';
import side_slider_3 from '../../assets/images/homepage/side_slider_3.png';
import topHeader1 from '../../assets/images/homepage/topHeader1.png';
import appImg from '../../assets/images/homepage/appImg.png';
import chef from '../../assets/images/homepage/chef.png';
import bannerMeal from '../../assets/images/homepage/bannerMeal.png';
import MobileDownloadStores from '../../components/MobileDownloadStores';
import HomeCard from '../../components/HomeCard';
import SearchRestaurant from '../../components/SearchRestaurant';
// import SliderComponent from '../../components/home/banner_slider';
// import { Modal } from 'antd';


const HomeIndex = () => {
    const navigate = useNavigate()
    const [walletData, setWalletData] = useState(false)
    const [isVendor, setIsVendor] = useState(false)
    const [isRestaurant, setIsRestaurant] = useState(false)
    const [isHidden, setIsHidden] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [partnerModalOpen, setPartnerModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
    const showPartnerModal = () => {
        setPartnerModalOpen(true);
    };
    
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const handlePartnerCancel = () => {
        setPartnerModalOpen(false);
    };

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title,
            description: message,
            placement: "bottom-right"
        });
    };

    const toggleBalanceVisibility = () => {
        setIsHidden(!isHidden);
    };

    const formatHiddenBalance = (balance) => {
        const balanceString = (+balance).toFixed(2);
        const [intPart, decimalPart] = balanceString.split('.');

        const formattedIntPart = intPart.replace(/[0-9]/g, '*').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const formattedDecPart = decimalPart.replace(/[0-9]/g, '*').replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return `${formattedIntPart}.${formattedDecPart}`;
    };

    const fetchUserWalletBalance = async () => {
        try {
            let fetchUserAccountBalance = await _fetch_user_wallet_data();
            setWalletData(fetchUserAccountBalance?.data?.data);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message !== "Unauthorized") {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', 'An error occurred while loading wallet balance. Please reload page to try again')
            }
            setWalletData(false);
        }
    }

    const handleRestaurantLink = async () => {
        try{
           let result = await _get_profile();
           setIsVendor(result?.data?.data.is_vendor);
           if (isVendor && !isRestaurant){
            setPartnerModalOpen(true);
           }
        } catch(err){
        }
    }

    useEffect(() => {
        fetchUserWalletBalance();
        handleRestaurantLink()
    }, [])
  return ( 
    <>
        <div>
            <Navbar noShadow={true} adjustNavSize={true} />
            <div className=' h-screen max-h-screen min-h-screen w-full' >
                <Row justify={'center'}>
                    <Col xs={22}>
                        <div className="lg:flex lg:justify-between items-center">
                            <div>
                                <img src={topHeader1} alt="" />
                            </div>
                            <div className='lg:py-5 max-sm:my-10' >
                                <h3 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl '>Families & Friends </h3>
                                <h3 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl '>Eats Cheaper</h3>
                                <h3 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl '> With<span className='text-[#44843F] font-bold'> Konnect.</span></h3>
                                <div className="w-full lg:mt-52">
                                    <SearchRestaurant />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            
            {/* Banner 2 */}
            
            <div className='w-full landing_hero ' >
                <Row justify={'center'}>
                    <Col xs={24}>
                        <div className="lg:flex lg:justify-between items-center w-full ">
                            <div className='w-full'>
                                <h3 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl'>Experience <br /> The App<br />  Get 30% Discount</h3>
                                <MobileDownloadStores />
                            </div>
                            {/* <div> */}
                                <img src={appImg} alt="" />
                            {/* </div> */}
                        </div>
                    </Col>
                </Row>
            </div>
            {/* <div className='landing_hero py-5 lg:mt-20'>
                <Row justify={'center'}>
                    <Col xs={24} >
                        <div className='lg:px-32 lg:py-3'>
                            <h3 className='lg:text-8xl xl:text-9xl text-5xl max-sm:text-center '>Experience <br /> The App<br />  Get 30% Discount</h3>
                            <MobileDownloadStores />
                        </div>
                    </Col>
                </Row>
            </div> */}

            {/* Banner 3 */}
            <div className=' h-screen max-h-screen min-h-screen w-full mt-32' >
                <Row justify={'center'}>
                    <Col xs={24}>
                        <div className="lg:flex lg:justify-between items-center w-full">
                            {/* <div> */}
                                <img src={chef} alt="" />
                            {/* </div> */}
                            <div  className='w-full lg:py-5  max-sm:my-10 ' >
                                <h3 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl '>Do you cook & sell? </h3>
                                <h3 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl '>Then partner with us</h3>
                                <h3 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl '>To grow your business</h3>
                                <div className='flex flex-col justify-center items-center mt-5 lg:mt-32'>
                                    <button className="rounded-full focus:outline-none border-none flex justify-between  items-center rounded-button text-white py-3 px-6">
                                        <p>Start Here</p>   
                                        <span>
                                            <img onClick={showPartnerModal} className=' w-6 h-6 lg:w-10 lg:h-10 ' src={right_circle_arrow} alt='img' />
                                        </span>
                                    </button>
                            </div>
                          
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            {/* Slider card */}
            <HomeCard />

            <div className='w-full lg:mt-64 landing_hero  ' >
                <Row justify={'center'}>
                    <Col xs={24}>
                        <div className="lg:flex lg:justify-between items-center w-full ">
                            <div  className='w-full'>
                                <h3 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl'>Join the Konnect</h3>
                                <h3 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl'>Marketing team </h3>
                                <h3 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl'>Earn cash monthly</h3>
                                <div className='max-sm:flex max-sm:justify-center' style={{display: "flex", alignItems: "center", gap: "10px", marginTop: '10px'}}>
                                    <button  onClick={showModal} className='rounded-button'>Start Here</button>
                                </div>
                            </div>
                            <div>
                                <img src={side_banner5} alt="" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            {/* Banner 6 */}
            <div className='h-screen max-h-screen min-h-screen flex items-center w-full' >
                <Row justify={'center'}>
                    <Col xs={24}>
                        <div className="lg:flex lg:justify-between items-center w-full">
                            <div  className='w-full max-sm:mb-10' >
                                <h1 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl'>Think Breakfast</h1>
                                <h1 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl'>Lunch & Dinner</h1>
                                <h1 className='lg:text-8xl xl:text-9xl max-sm:text-center text-7xl text-[#44843F]'style={{color: ""}}>Think Konnect</h1>
                                <div className='max-sm:flex max-sm:justify-center' style={{display: "flex", alignItems: "center", gap: "10px", marginTop: '10px'}}>
                                    <button  onClick={showModal} className='rounded-button'>Start Here</button>
                                </div>
                            </div>
                            <div>
                                <img src={bannerMeal} alt="" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Footer />
        </div>
    </>

  )  
} 

export default HomeIndex
import { Button, Result } from "antd"
import { Link } from "react-router-dom"

const Restaurant = () => {
  return (
    <>
      <div style={{ height: "100vh", background: "white" }}></div>
      <div style={{ width: "100%", height: "100vh", position: "absolute", top: "0", left: "0", display: "flex" }}>
        <div style={{ width: "fit-content", background: 'white', margin: "auto", zIndex: "100" }}>
          <Result
            status="404"
            title="Under Construction"
            subTitle="Restaurants will open for order from end of October"
            extra={[<Link to='/home'><Button>Go Back</Button></Link>]}
          />
        </div>
      </div>
    </>
  )
}

export default Restaurant
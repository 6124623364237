import React from 'react'
import {Button, Col, Divider, Drawer, Form, Image, Input, Row, Select, Spin, Tabs, Upload} from 'antd'
import {CloseOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons'

import RestaurantSideNav from '../../components/dashboard/restaurant_side_nav'
import Navbar from '../../components/Navbar'
import FoodCard from '../../components/Restaurant/Menu/FoodCard'

const RestaurantMenu = () => {
	const {TabPane} = Tabs;
	const [form] = Form.useForm();
	const newAntIcon = <LoadingOutlined style={{fontSize: 24, color: '#008060'}} spin />;

	// States
	const [spinnerLoading, setSpinnerLoading] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [previewOpen, setPreviewOpen] = React.useState(false);
	const [previewImage, setPreviewImage] = React.useState('');
	const [price, setPrice] = React.useState('');
	const [discount, setDiscount] = React.useState('');
	const [fileList, setFileList] = React.useState([
		{
			uid: '-1',
			name: 'image.png',
			status: 'done',
			url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
		},
		{
			uid: '-2',
			name: 'image.png',
			status: 'done',
			url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
		}
	]);

	const foodItems = [
		{
			name: 'Fried Rice & Chicken',
			description: '1 portion Fried Rice and Chicken',
			price: '₦1000',
			originalPrice: '₦1200',
			available: false,
			imageUrl: 'https://via.placeholder.com/300x200?text=Fried+Rice+%26+Chicken',
		},
		{
			name: 'Jollof Rice & Chicken',
			description: '1 portion Jollof Rice and Chicken',
			price: '₦1000',
			originalPrice: '₦1200',
			available: true,
			imageUrl: 'https://via.placeholder.com/300x200?text=Jollof+Rice+%26+Chicken',
		},
		{
			name: 'Pasta & Chicken',
			description: '1 portion Pasta and Chicken',
			price: '₦1000',
			originalPrice: '₦1200',
			available: true,
			imageUrl: 'https://via.placeholder.com/300x200?text=Pasta+%26+Chicken',
		},
		{
			name: 'Spaghetti Bolognese',
			description: '1 portion Spaghetti Bolognese',
			price: '₦1000',
			originalPrice: '₦1200',
			available: false,
			imageUrl: 'https://via.placeholder.com/300x200?text=Spaghetti+Bolognese',
		}
	];

	// Functions
	const drawerVisibility = () => setVisible(!visible);
	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
	};

	const changeImage = ({fileList: newFileList}) => setFileList(newFileList);

	const uploadButton = (
		<button style={{border: 0, background: 'none'}} type="button">
			<PlusOutlined />
			<div style={{marginTop: 8}}>Upload</div>
		</button>
	);

	// Handle price change and update form state
	const handlePriceChange = (value) => {
		let numericValue = value.replace(/[^\d.]/g, '');

		// Set state and update form field
		setPrice(numericValue);
		form.setFieldsValue({price: numericValue});
	};

	// Handle discount change and update form state
	const handleDiscountChange = (value) => {
		let numericValue = value.replace(/[^\d.]/g, '');

		// Set state and update form field
		setDiscount(numericValue);
		form.setFieldsValue({discount: numericValue});
	};

	// Optional: Handle formatting to two decimal places on blur
	const handlePriceBlur = () => {
		if (price) {
			const formattedPrice = parseFloat(price).toFixed(2);
			setPrice(formattedPrice);
			form.setFieldsValue({price: formattedPrice});
		}
	};

	const handleDiscountBlur = () => {
		if (discount) {
			const formattedDiscount = parseFloat(discount).toFixed(2);
			setDiscount(formattedDiscount);
			form.setFieldsValue({discount: formattedDiscount});
		}
	};

	const onFinish = (values) => {
		console.log('Form values:', values);
	};


	return (
		<div className="userprofile">
			<Spin spinning={spinnerLoading} indicator={newAntIcon}>
				<div className="profile-content">
					<Navbar />
					<div className="gray_bg_profile">
						<div className="profile_to_left">
							<div className="">
								<div className="profile_nav">
									<RestaurantSideNav />
								</div>
							</div>
						</div>
						<div className="profile_to_right">
							<div className="contain">
								<div className="main_container">
									<div className="main_container_title_div">
										<div className="add-menu-button-container">
											<h5>My Menu</h5>
											<Button type="primary" onClick={drawerVisibility} className="add-menu-button" icon={<PlusOutlined />} shape="round">Add new Menu</Button>
										</div>
										<Divider style={{margin: '0px 0 5px'}} />
									</div>

									<Tabs type='card'>
										<TabPane tab="All" key="1">
											<Row gutter={[16, 16]}>
												{foodItems.map((food, index) => (
													<Col xs={12} sm={12} md={8} lg={6} key={index}>
														<FoodCard food={food} />
													</Col>
												))}
											</Row>
										</TabPane>
										<TabPane tab="Available" key="2">

										</TabPane>
										<TabPane tab="Not Available" key="3">

										</TabPane>
									</Tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Spin >

			{/* Drawer */}
			<Drawer title="New Menu" placement="right" onClose={drawerVisibility} open={visible} width={500} closeIcon={<CloseOutlined />} >
				<div className="new-menu-drawer">
					{/* Upload Menu Images */}
					<Form form={form} layout="vertical" onFinish={onFinish}>
						<Form.Item label="Upload Menu Images">
							<Upload
								action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
								listType="picture-card"
								fileList={fileList}
								onPreview={handlePreview}
								onChange={changeImage}
							>
								{fileList.length >= 8 ? null : uploadButton}
							</Upload>
							{previewImage && (
								<Image
									wrapperStyle={{display: 'none'}}
									preview={{
										visible: previewOpen,
										onVisibleChange: (visible) => setPreviewOpen(visible),
										afterOpenChange: (visible) => !visible && setPreviewImage(''),
									}}
									src={previewImage}
								/>
							)}
						</Form.Item>

						{/* Select Category */}
						<Form.Item label="Select Category" name="category">
							<Select placeholder="Select">
								<Select.Option value="starter">Starter</Select.Option>
								<Select.Option value="main">Main Course</Select.Option>
								<Select.Option value="dessert">Dessert</Select.Option>
							</Select>
						</Form.Item>

						{/* Menu Name */}
						<Form.Item label="Menu Name" name="menuName">
							<Input placeholder="Enter menu name" />
						</Form.Item>

						{/* Key Allergens */}
						<Form.Item label="Key allergens" name="allergens">
							<Input.TextArea placeholder="Enter allergens" rows={3} />
						</Form.Item>

						{/* Price and Discount */}
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label="Price" name="price">
									<Input prefix="NGN" value={price} onChange={(e) => handlePriceChange(e.target.value)} onBlur={handlePriceBlur} placeholder="Enter price" />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Discount %" name="discount">
									<Input suffix="%" value={discount} onChange={(e) => handleDiscountChange(e.target.value)} onBlur={handleDiscountBlur} placeholder="Enter discount" />
								</Form.Item>
							</Col>
						</Row>

						{/* Extras Section */}
						<Divider />
						<h4>Extras</h4>
						<Form.List name="extras">
							{(fields, {add, remove}) => (
								<>
									{fields.map(({key, name, fieldKey, ...restField}) => (
										<Row gutter={16} key={key}>
											<Col span={10}>
												<Form.Item
													{...restField}
													name={[name, 'extraName']}
													fieldKey={[fieldKey, 'extraName']}
													rules={[{required: true, message: 'Missing extra name'}]}
												>
													<Input placeholder="Name" />
												</Form.Item>
											</Col>
											<Col span={10}>
												<Form.Item
													{...restField}
													name={[name, 'extraPrice']}
													fieldKey={[fieldKey, 'extraPrice']}
													rules={[{required: true, message: 'Missing extra price'}]}
												>
													<Input placeholder="Price" />
												</Form.Item>
											</Col>
											<Col span={4}>
												<Button
													type="link"
													onClick={() => remove(name)}
													icon={<CloseOutlined style={{color: 'red', fontSize: '18px'}} />}
												/>
											</Col>
										</Row>
									))}
									<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
										Add Extra
									</Button>
								</>
							)}
						</Form.List>

						{/* Submit Button */}
						<Form.Item>
							<Button type="primary" htmlType="submit" className="menu-update-btn">Update</Button>
						</Form.Item>
					</Form>
				</div>
			</Drawer>
		</div >
	)
}

export default RestaurantMenu
import React from 'react';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from "react-owl-carousel";
// import rice from '../../assets/images/homepage/rice_plate.png';
import airtime from '../../assets/images/homepage/airtime.png';
import foodBox from '../../assets/images/homepage/food-box-wooden.png';
import { useNavigate } from 'react-router-dom';

const HomeSlider = () => {
    const navigate = useNavigate()
    const responsive = {
        0: {
            items: 1,
            nav: false,
            margin: 10,
            stagePadding: 20
        },
        600: {
            items: 1,
            nav: false,
            margin: 20,
            stagePadding: 20
        },
        1000: {
            items: 1,
            nav: false,
            margin: 10,
            stagePadding: 20,
        }
    }

    const sliderSettings = {
        responsive,
        autoplay: true,
        loop: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true
    };

    return (
        <div style={{ marginTop: "5rem" }}>
            <div className="slider-container">
                <OwlCarousel className="owl-theme" {...sliderSettings}>
                    <div className="slide_1">
                        <div className='grid_flex slider_content'>
                            <div>
                                <h1 style={{ color: "black" }}>Shop for groceries, foodstuffs & pay at delivery.</h1>
                                <div style={{ marginTop: '30px' }}>
                                    <button onClick={() => navigate('/shop')} className='rounded-button'>Go to shop</button>
                                </div>
                            </div>
                            <div className='home_side_image'>
                                <img className='rice' src={foodBox} alt="food box" />
                            </div>
                        </div>
                    </div>
                    <div className="slide_2">
                        <div className='grid_flex slider_content'>
                            <div>
                                <h1>Recharge your airtime and get 8% cash bonus.</h1>
                                <div style={{ marginTop: '30px' }}>
                                    <button onClick={() => navigate('/profile/loyalty')} className='rounded-button'>My wallet</button>
                                </div>
                            </div>
                            <div className='home_side_image'>
                                <img className='rice' src={airtime} alt="Airtime" />
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </div>
    )
}

export default HomeSlider
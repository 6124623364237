import React from 'react'
import {Divider, Spin, Tabs, Image, Upload, message, Form, Input, Button, Select, TimePicker, notification, Tag} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'
import {PlusOutlined} from '@ant-design/icons';
import NaijaStates from "naija-state-local-government";
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';

//	styles
import '../../assets/css/shop.css'
import '../../assets/css/restaurnt.css'

// compoenents
import Navbar from '../../components/Navbar'
import RestaurantSideNav from '../../components/dashboard/restaurant_side_nav'
import {_add_restaurant} from '../../common/axios_services';

const RestaurantProfile = () => {
	const {TabPane} = Tabs;
	const [form] = Form.useForm()
	const navigate = useNavigate()
	const newAntIcon = <LoadingOutlined style={{fontSize: 24, color: '#008060'}} spin />;
	const openNotificationWithIcon = (type, title, message) => {
		notification[type]({
			message: title,
			description: message,
			placement: "bottom-right"
		});
	};

	// States
	const timeFormat = 'mm:ss';
	const openTimeFormat = 'HH:mm'; // Use HH:mm format for hours and minutes
	const [all_states] = React.useState(["Lagos"]);
	const [restaurantError, setRestaurantError] = React.useState('');
	const [spinnerLoading, setSpinnerLoading] = React.useState(false);
	const [banner_url, setBannerUrl] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [updatingProfile, setUpdatingProfile] = React.useState(false);
	const [all_lgas, set_all_lgas] = React.useState([]);
	const [minTime, setMinTime] = React.useState(moment('00:00', timeFormat));
	const [maxTime, setMaxTime] = React.useState(moment('00:00', timeFormat));
	const [openTime, setOpenTime] = React.useState(moment('09:00', openTimeFormat));
	const [closeTime, setCloseTime] = React.useState(moment('17:00', openTimeFormat));
	const [countryCode, setCountryCode] = React.useState('234');
	const [phoneNumber, setPhoneNumber] = React.useState();
	const [selectedTags, setSelectedTags] = React.useState(['']);
	const [previewCookingAreaImage, setPreviewCookingAreaImage] = React.useState('');
	const [previewOpen, setPreviewOpen] = React.useState(false);
	const [cookingAreaFileList, setCookingAreaFileList] = React.useState([
		{
			uid: '-1',
			name: 'image.png',
			status: 'done',
			url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
		},
		{
			uid: '-2',
			name: 'image.png',
			status: 'done',
			url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
		}
	]);

	const foodHygieneTags = ['Gloves', 'apron', 'boots'];

	// Functions
	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.readAsDataURL(img);
		reader.addEventListener('load', () => callback(reader.result));
	}

	const preBannerUpload = (file) => {
		const isImage = file.type.startsWith('image/');
		if (!isImage) {
			message.error('You can only upload image files!');
			return Upload.LIST_IGNORE;
		}

		const isLt5MB = file.size / 1024 / 1024 < 5;
		if (!isLt5MB) {
			message.error('Image must be smaller than 5MB!');
			return Upload.LIST_IGNORE;
		}

		return true;
	};

	const changeBanner = info => {
		getBase64(info.file.originFileObj, imageUrl =>
			setBannerUrl(imageUrl),
			setLoading(false),
		);
		const reader = new FileReader();

		reader.readAsDataURL(info.file.originFileObj);
		reader.addEventListener('load', () => {});
	};

	const uploadButton = (
		<div style={{width: '100%', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{marginTop: 8}}>Upload Banner</div>
		</div>
	);

	const getCookingAreaBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleCookingAreaPreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getCookingAreaBase64(file.originFileObj);
		}
		setPreviewCookingAreaImage(file.url || file.preview);
		setPreviewOpen(true);
	};

	const changeCookingAreaImage = ({fileList: newFileList}) => setCookingAreaFileList(newFileList);

	const cookingAreaUploadButton = (
		<button style={{border: 0, background: 'none'}} type="button">
			<PlusOutlined />
			<div style={{marginTop: 8}}>Upload</div>
		</button>
	);

	// Submit restaurant profile
	const handlePhoneChange = (phone, country) => {
		const dialCode = `${country.dialCode}`;
		setCountryCode(dialCode);

		// Remove the country code from the start of the phone number
		const phoneWithoutCode = phone.startsWith(dialCode)
			? phone.slice(dialCode.length).trim()
			: phone;
		setPhoneNumber(phoneWithoutCode);
	};

	const finishProfile = async (values) => {
		const data = {
			name: values.name,  // Restaurant name
			display_image: banner_url || "",  // Restaurant display image URL
			description: values.description,  // Restaurant description
			longitude: '0.00',  // You can replace it with actual longitude if available
			latitude: '0.00',  // You can replace it with actual latitude if available
			address: values.address,  // Pickup address
			state: values.state,  // State
			city: values.lga,  // LGA (used as city)
			instagram: values.instagram_handle,  // Instagram handle
			contact_name: values.contact || "",  // Contact name (optional)
			contact_phone_code: countryCode || "",  // Contact phone code
			contact_phone: phoneNumber || "",  // Contact phone number
			contact_email: values.contact_email,  // Contact email
			min_ready_time: values.min_ready_time.format(timeFormat),  // Minimum ready time
			max_ready_time: values.max_ready_time.format(timeFormat),  // Maximum ready time
			open_time: values.open_time.format(openTimeFormat),  // Restaurant open time
			close_time: values.close_time.format(openTimeFormat),  // Restaurant close time
			business_document: "",  // Placeholder if needed
			cooking_area_image: banner_url || "",  // Cooking area image
			storage_facility: values.storage_facility,  // Storage facility description
			staff_count: values.staff_count,  // Staff count
			food_hygiene: values.food_hygiene,  // Food hygiene description
			active_email: values.active_email,  // Active email
			is_open: true  // Default to open (you can adjust based on logic)
		};
		console.log('create restaurant data', data);
		try {
			setUpdatingProfile(true);
			const addRestaurant = await _add_restaurant(data)
			console.log('create restaurant response', addRestaurant);
			openNotificationWithIcon('success', addRestaurant?.data?.title, addRestaurant?.data?.message);
		} catch (err) {
			if (err.response) {
				if (err.response.data.message === "Unauthorized") {
					localStorage.removeItem('konnect_token')
					navigate('/signin')
				}
				openNotificationWithIcon('error', err.response.data.message)
			} else {
				openNotificationWithIcon('error', err.message)
			}
		} finally {
			setUpdatingProfile(false);
		}
	}

	const onChangeState = (value) => {
		if (value) {
			form.setFieldsValue({lga: ""});
			const allLGAsinAState = NaijaStates.lgas(`${value}`);
			set_all_lgas(allLGAsinAState.lgas);
		}
	};

	// Handle changes for Minimum Time
	const handleMinTimeChange = (time, timeString) => {
		const selectedTime = moment(timeString, timeFormat);
		// Check if minimum time is greater than the current maximum time
		if (selectedTime.isSameOrAfter(maxTime)) {
			setRestaurantError('Minimum ready time cannot be greater than or equal to Maximum ready time')
		} else {
			setRestaurantError('')
			setMinTime(selectedTime);
		}
	};

	// Handle changes for Maximum Time
	const handleMaxTimeChange = (time, timeString) => {
		const selectedTime = moment(timeString, timeFormat);
		// Check if maximum time is less than the current minimum time
		if (selectedTime.isSameOrBefore(minTime)) {
			setRestaurantError('Maximum ready time must be greater than Minimum ready time')
		} else {
			setRestaurantError('')
			setMaxTime(selectedTime);
		}
	};

	// Handle Open Time change
	const handleOpenTimeChange = (time, timeString) => {
		const selectedTime = moment(timeString, openTimeFormat);
		// Validate that open time is not after close time
		if (selectedTime.isSameOrAfter(closeTime)) {
			setRestaurantError('Open time must be earlier than Close time')
		} else {
			setRestaurantError('')
			setOpenTime(selectedTime);
		}
	};

	// Handle Close Time change
	const handleCloseTimeChange = (time, timeString) => {
		const selectedTime = moment(timeString, openTimeFormat);
		// Validate that close time is not before open time
		if (selectedTime.isSameOrBefore(openTime)) {
			setRestaurantError('Close time must be later than Open time')
		} else {
			setRestaurantError('')
			setCloseTime(selectedTime);
		}
	};

	const checkTag = (tag, checked) => {
		const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
		console.log('You are interested in: ', nextSelectedTags);
		setSelectedTags(nextSelectedTags);
	};

	return (
		<div className="userprofile">
			<Spin spinning={spinnerLoading} indicator={newAntIcon}>
				<div className="profile-content">
					<Navbar />
					<div className="gray_bg_profile">
						<div className="profile_to_left">
							<div className="">
								<div className="profile_nav">
									<RestaurantSideNav />
								</div>
							</div>
						</div>
						<div className="profile_to_right">
							<div className="contain">
								<div className="main_container">
									<div className="main_container_title_div">
										<h5>Profile</h5>
										<Divider style={{margin: '0px 0 5px'}} />
									</div>

									{/* Restaurant Profile */}
									<div style={{marginBottom: '20px'}} className="avatar-uploader">
										<Upload name="avatar" listType="picture-card" showUploadList={false} multiple={false} onChange={changeBanner} beforeUpload={preBannerUpload} >
											{banner_url ? <img src={banner_url} alt="avatar" style={{width: '100%', height: '200px', objectFit: 'cover'}} className="adminCourseUpload" /> : uploadButton}
										</Upload>
									</div>
									<Tabs type='card'>
										<TabPane tab="Restaurant Profile" key="1">
											<Form form={form} layout="vertical" onFinish={finishProfile} initialValues={{}} style={{padding: '20px'}}>
												{restaurantError && <p className="error-message">{restaurantError}</p>}
												<div>
													<Form.Item label="Upload Cooking Area">
														<Upload
															action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
															listType="picture-card"
															fileList={cookingAreaFileList}
															onPreview={handleCookingAreaPreview}
															onChange={changeCookingAreaImage}
														>
															{cookingAreaFileList.length >= 8 ? null : cookingAreaUploadButton}
														</Upload>
														{previewCookingAreaImage && (
															<Image
																wrapperStyle={{display: 'none'}}
																preview={{visible: previewOpen, onVisibleChange: (visible) => setPreviewOpen(visible), afterOpenChange: (visible) => !visible && setPreviewCookingAreaImage('')}}
																src={previewCookingAreaImage}
															/>
														)}
													</Form.Item>
												</div>
												<div className="form-flex-2">
													<Form.Item label="Name (as to be displayed)" name="name" rules={[{required: true, message: 'Please input your restaurant name!'}]}>
														<Input type="text" style={{height: '5rem'}} />
													</Form.Item>
													<Form.Item name="state" label="State" className="form-group" rules={[{required: true, message: "Please fill input is required"}]}>
														<Select onChange={onChangeState} placeholder="Select State" name="state" style={{width: "100%", height: "5rem"}}>
															{all_states.map((state) => (
																<Select.Option key={state} value={state}>{state}</Select.Option>
															))}
														</Select>
													</Form.Item>
												</div>

												<div className="form-flex-2">
													<Form.Item name="lga" label="LGA" className="form-group" rules={[{required: true, message: "Please fill input is required"}]} >
														<Select placeholder="Select LGA" name="state" style={{width: "100%", height: "5rem", }} >
															{all_lgas.map((lga) => (
																<Select.Option key={lga} value={lga}>{lga}</Select.Option>
															))}
														</Select>
													</Form.Item>
													<Form.Item label="Pickup address" name="address" rules={[{required: true, message: 'Please input your restaurant pickup address!'}]}>
														<Input type="text" style={{height: '5rem'}} />
													</Form.Item>
												</div>

												<div className="form-flex-2">
													<Form.Item label="Contact" name="contact">
														<Input type="text" style={{height: '5rem'}} />
													</Form.Item>
													<Form.Item label="Contact phone" name="contactNumber" rules={[{required: true, message: 'Please input your restaurant contact phone!'}]}>
														<PhoneInput country={'ng'} onChange={handlePhoneChange} containerStyle={{width: '100%'}} inputStyle={{height: '5rem', width: '100%'}} />
													</Form.Item>
												</div>

												<div className="form-flex-2">
													<Form.Item label="Instagram Handle" name="instagram_handle">
														<Input type="text" style={{height: '5rem'}} />
													</Form.Item>
													<Form.Item label="Contact Email" name="contact_email" rules={[{required: true, message: 'Please input your restaurant contact email!'}]}>
														<Input type="email" style={{height: '5rem'}} />
													</Form.Item>
												</div>

												<div className="form-flex-2">
													<Form.Item label="Active Email" name="active_email" rules={[{required: true, message: 'Please input your restaurant active email!'}]}>
														<Input type="email" style={{height: '5rem'}} />
													</Form.Item>
													<Form.Item label="Staff Count" name="staff_count" rules={[{required: true, message: 'Please input your restaurant staff count!'}]}>
														<Input type="number" min="1" className='h-20 w-full appearance-none' />
													</Form.Item>
												</div>

												<div className="form-flex-2">
													<Form.Item label="Storage Facility" name="storage_facility" rules={[{required: true, message: 'Please input your restaurant storage facility!'}]}>
														<Input type="text" style={{height: '5rem'}} />
													</Form.Item>
													<Form.Item label="Food Hygiene" name="food_hygiene" rules={[{required: true, message: 'Please input your restaurant food hygiene info!'}]}>
														{foodHygieneTags.map((tag) => (
															<Tag.CheckableTag key={tag} checked={selectedTags.includes(tag)} onChange={(checked) => checkTag(tag, checked)} className={`p-3 rounded-xl ${selectedTags.includes(tag) ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black hover:bg-gray-200'}`}>
																{tag}
															</Tag.CheckableTag>
														))}
													</Form.Item>
												</div>

												<div className="form-flex-2">
													<Form.Item label="Minimum ready time" name="min_ready_time" rules={[{required: true, message: 'Please select your minimum ready time!'}]}>
														<TimePicker size="large" className="custom-timepicker" format={timeFormat} value={minTime} onChange={handleMinTimeChange} showNow={false} />
													</Form.Item>
													<Form.Item label="Maximum ready time" name="max_ready_time" rules={[{required: true, message: 'Please select your maximum ready time!'}]}>
														<TimePicker size="large" className="custom-timepicker" format={timeFormat} value={maxTime} onChange={handleMaxTimeChange} showNow={false} />
													</Form.Item>
												</div>

												<div className="form-flex-2">
													<Form.Item label="Open time" name="open_time" rules={[{required: true, message: 'Please select your restaurant open time!'}]}>
														<TimePicker format={openTimeFormat} value={openTime} onChange={handleOpenTimeChange} size="large" showNow={false} className="custom-timepicker" />
													</Form.Item>
													<Form.Item label="Close time" name="close_time" rules={[{required: true, message: 'Please select your restaurant close time!'}]}>
														<TimePicker format={openTimeFormat} value={closeTime} onChange={handleCloseTimeChange} size="large" showNow={false} className="custom-timepicker" />
													</Form.Item>
												</div>

												<div>
													<Form.Item label="Description" name="description">
														<Input.TextArea placeholder="Enter description here..." rows={6} className="custom-textarea" />
													</Form.Item>
												</div>

												{updatingProfile || !banner_url ? (
													<button className="restaurnat_profile_button bg-gray-300 text-gray-500 cursor-not-allowed border-none" disabled >
														{updatingProfile ? (<>Updating profile. Please wait <Spin style={{marginLeft: '10px'}} indicator={newAntIcon} /></>) :
															(<>Please upload a banner to proceed</>)}
													</button>
												) : (
													<button className="restaurnat_profile_button bg-white text-black hover:bg-gray-100 border border-black transition-colors" htmlType="submit" >Update profile</button>
												)}
											</Form>
										</TabPane>
										<TabPane tab="Quality assurance commitment" key="2">
											<div className='restaurant_qac'>
												<h2>ALLERGENS </h2>
												<p className='qac_text'>You can call  &lt;&lt;Restaurant name&gt;&gt; to ask about their ingredients and allergen information, production or cooking methods. Call  &lt;&lt;Restaurant name&gt;&gt; on  &lt;&lt;+234xxxxxxxxx&gt;&gt;</p>
												<div style={{margin: '3%'}}></div>
												<h2>HYGIENE RATING</h2>
												<p className='qac_text'>The FSA food hygiene rating is a good standing meaning the kitchen has good hygiene practices.</p>
												<div style={{margin: '3%'}}></div>
												<h2>LOCATION</h2>
												<p className='qac_text'> &lt;&lt;Restaurant address&gt;&gt;</p>
											</div>
											<div className='restaurant_profile_note'>
												<h2>NOTES</h2>
												<p className='qac_text'>All dishes may contain traces of the following allergens: Gluten, Crustaceans, Eggs, Fish, Peanuts, Soybeans, Milk, Nuts (e.g. almonds, hazelnuts, walnuts, cashews), For any questions regarding the allergen contents of specific dishes please contact the restaurant directly.</p>
											</div>
										</TabPane>
									</Tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Spin >
		</div >
	)
}

export default RestaurantProfile
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Card, Input, Tag, Typography} from 'antd';
import React from 'react'

const {Title, Text} = Typography;

const FoodCard = ({food}) => {
	const {name, description, price, originalPrice, available, imageUrl} = food;
	const [quantity, setQuantity] = React.useState(1);

	// Helper function to truncate description text
	const truncateText = (text, maxLength = 20) => {
		if (text.length > maxLength) {
			return `${text.substring(0, maxLength)}...`;
		}
		return text;
	};

	// Handlers for incrementing and decrementing quantity
	const increment = () => setQuantity((prevQuantity) => prevQuantity + 1);
	const decrement = () => {
		if (quantity > 1) {
			setQuantity((prevQuantity) => prevQuantity - 1);
		}
	};

	return (
		<Card
			className={`food-card ${available ? '' : 'not-available'}`}
			cover={<img alt={name} src={imageUrl} />}
			hoverable
		>
			{!available && <Tag color="red" className="not-available-tag">Not Available</Tag>}
			<div className="food-info">
				<Title level={5}>{name}</Title>
				<Text type="secondary">{truncateText(description)}</Text>
				<div className="price-section">
					<Text delete>{originalPrice}</Text>
					<Text strong style={{marginLeft: '10px'}}>{price}</Text>
				</div>
			</div>
			<div className="food-actions" style={{display: 'flex', alignItems: 'center'}}>
				<Button icon={<MinusOutlined />} onClick={decrement} disabled={!food.available || quantity <= 1} />
				<Input value={quantity} onChange={(e) => setQuantity(Number(e.target.value))} style={{width: 50, textAlign: 'center', margin: '0 10px'}} disabled={!food.available} />
				<Button icon={<PlusOutlined />} onClick={increment} disabled={!food.available} />
			</div>
		</Card>
	);
};

export default FoodCard;